// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordVisibility = {
  data() {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
export const toggleConfirmPasswordVisibility = {
  data() {
    return {
      ConfirmpasswordFieldType: 'password',
    }
  },
  methods: {
    toggleConfirmPasswordVisibility() {
      this.ConfirmpasswordFieldType = this.ConfirmpasswordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
export const toggleOldPasswordVisibility = {
  data() {
    return {
      OldpasswordFieldType: 'password',
    }
  },
  methods: {
    toggleOldPasswordVisibility() {
      this.OldpasswordFieldType = this.OldpasswordFieldType === 'password' ? 'text' : 'password'
    },
  },
}

export const _ = null
